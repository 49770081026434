import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { LogoCard, PrimaryButton } from '@shared/components';
import {
  ContainerItem,
  PaginationContainer,
  SectionHeading,
} from './integration-partner.styles';
import { CSIntegrationPartnerPage } from '@shared/contentstack';
import {
  filterDefinedComponents,
  mappedComponent,
  filterDefinedBanners,
} from '@shared/utilities/page.util';
import { default_breakpoints } from '@src/styles/breakpoints';
import { processSimpleTextToBaseIdFormat } from '@src/shared/utilities/string.utils';

const IntegrationPartnerPage = ({
  pageContext,
}: {
  pageContext: CSIntegrationPartnerPage;
}) => {
  const {
    hero_banner,
    featured_section_title,
    featured_partners,
    partnerCardNodes,
    components,
  } = pageContext || {};
  const initialCardsResultsLength = 9;
  const totalPartnerCards = partnerCardNodes?.length || 0;

  const [itemsShowing, setItemsShowing] = React.useState(
    initialCardsResultsLength
  );
  const remainingResults = totalPartnerCards - itemsShowing;

  return (
    <>
      {hero_banner &&
        filterDefinedBanners(hero_banner).map((comp: any, index: number) =>
          mappedComponent(comp.type, comp, index)
        )}
      <CMSComponentWrapper maxWidth={default_breakpoints.cw} margin={[70, 115]}>
        {featured_partners && (
          <>
            <SectionHeading
              id={`integration-partner-${processSimpleTextToBaseIdFormat(
                featured_section_title || ''
              )}`}
            >
              {featured_section_title}
            </SectionHeading>
            <ContainerItem>
              {featured_partners.map(
                ({
                  partner_name,
                  card_content,
                  partner_logo,
                  uid,
                  display_logo_image,
                  display_video,
                  video_id,
                }) => (
                  <LogoCard
                    uid={uid}
                    key={`featured-${partner_name}`}
                    title={partner_name}
                    copy={card_content}
                    image={partner_logo}
                    variant="mobile-stacked"
                    display_logo_image={display_logo_image}
                    display_video={display_video}
                    video_id={video_id}
                    playingVideoAsPopup={true}
                  />
                )
              )}
            </ContainerItem>
          </>
        )}
        {partnerCardNodes && (
          <>
            <SectionHeading id={'integration-partner-all'}>All</SectionHeading>
            <ContainerItem>
              {[...partnerCardNodes.slice(0, itemsShowing)]?.map(
                ({
                  partner_name,
                  card_content,
                  id,
                  partner_logo,
                  uid,
                  display_logo_image,
                  display_video,
                  video_id,
                }) => (
                  <LogoCard
                    uid={uid}
                    key={id}
                    title={partner_name}
                    copy={card_content}
                    image={partner_logo}
                    variant="default"
                    display_logo_image={display_logo_image}
                    display_video={display_video}
                    video_id={video_id}
                    playingVideoAsPopup={true}
                  />
                )
              )}
            </ContainerItem>
            {itemsShowing < totalPartnerCards && (
              <PaginationContainer>
                <PrimaryButton
                  ariaLabel="see more"
                  onClick={() => setItemsShowing(totalPartnerCards)}
                  label="Load More"
                  size="large"
                  variant="contained"
                  icon="text"
                />
                <p>{remainingResults} More Results</p>
              </PaginationContainer>
            )}
          </>
        )}
      </CMSComponentWrapper>
      {filterDefinedComponents(components).map((comp: any, index: number) =>
        mappedComponent(comp.type, comp, index)
      )}
    </>
  );
};

export default IntegrationPartnerPage;
