export const INTEGRATED_PARTNERS_MOCK = {
  page_url: '/integration-partners',
  featured_partners: [
    {
      uid: 'bltd0843185ca373754',
      partner_name: 'Mock Partner 1',
      title: 'Kenect',
      card_content: {
        type: 'doc',
        attrs: {},
        uid: 'c1850fe0f00c467bb3a67a2cd6d77248',
        children: [
          {
            type: 'p',
            attrs: {},
            uid: '7ca13c1530bd46db95f3a55793a2b42a',
            children: [
              {
                text: 'SMS marketing at its finest! Send mass texts, generate intakes via text, and send automated messages to clients. The texts flow into your Kenect text inbox where you can respond to them, intelligently route them to individuals, teams, locations, or departments, and even create auto-responses.',
              },
            ],
          },
          {
            type: 'p',
            attrs: {},
            uid: '5d69adbd4ebd4bc1acafd708dc65ddb3',
            children: [
              {
                text: '',
                bold: true,
              },
            ],
          },
          {
            type: 'p',
            attrs: {},
            uid: '137bcaf25630488fb31f700e5bf3e90a',
            children: [
              {
                bold: true,
                text: 'Sign up for ',
              },
              {
                uid: 'efe1f8fc46534183a92d6d64ab1742b0',
                type: 'a',
                attrs: {
                  url: 'https://smart.kenect.com/assembly-neos',
                  target: '_blank',
                },
                children: [
                  {
                    text: 'Kenect demo',
                    bold: true,
                  },
                ],
              },
              {
                text: ' today',
                bold: true,
              },
            ],
          },
        ],
        _version: 6,
      },
      partner_logo: {
        image_alt_text: 'Kenect logo',
        image: {
          dimension: {
            width: 200,
            height: 35,
          },
          filename: 'Kenect_Logo.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=50&height=9&format=webp&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=100&height=18&format=webp&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=200&height=35&format=webp&quality=90 200w',
                  sizes: '(min-width: 200px) 200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=200&height=35&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=50&height=9&format=png&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=100&height=18&format=png&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=200&height=35&format=png&quality=90 200w',
                sizes: '(min-width: 200px) 200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 200,
            height: 35,
          },
        },
      },
    },
    {
      uid: 'blt524ba3cd104f7848',
      partner_name: 'Mock Partner 2',
      title: 'LawPay Partner',
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '9a98bfad6496447baa312d2bfb9af173',
        children: [
          {
            type: 'p',
            uid: 'a0ae761802c54ab696c53a6370cf08e6',
            attrs: {},
            children: [
              {
                text: 'Get paid faster with zero hassle. Accept payments online, send links to a secure payment portal via email, and more.',
              },
            ],
          },
          {
            type: 'p',
            uid: '78fed8ff19c64d078acbbab2482cf561',
            attrs: {},
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'p',
            uid: 'c4e476dbe59f4e2db013e1c1ee9caefa',
            attrs: {},
            children: [
              {
                text: 'Schedule your ',
                bold: true,
              },
              {
                uid: '4c0252e4433c48ea92d13160c740255c',
                type: 'a',
                attrs: {
                  url: 'https://www.lawpay.com/partners/assembly-software/',
                  target: '_blank',
                },
                children: [
                  {
                    bold: true,
                    text: 'LawPay demo',
                  },
                ],
              },
              {
                bold: true,
                text: ' today',
              },
            ],
          },
        ],
        _version: 13,
      },
      partner_logo: {
        image_alt_text: 'LawPay Logo',
        image: {
          dimension: {
            width: 200,
            height: 67,
          },
          filename: 'LawPay_Logo.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=50&height=17&format=webp&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=100&height=34&format=webp&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=200&height=67&format=webp&quality=90 200w',
                  sizes: '(min-width: 200px) 200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=200&height=67&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=50&height=17&format=png&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=100&height=34&format=png&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=200&height=67&format=png&quality=90 200w',
                sizes: '(min-width: 200px) 200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 200,
            height: 67,
          },
        },
      },
    },
    {
      uid: 'blte93013f8df100a42',
      partner_name: 'Mock Partner 3',
      title: 'Lexitas Partner',
      card_content: {
        type: 'doc',
        attrs: {},
        uid: 'ae39092707604774b5950fe2ecc7dbea',
        children: [
          {
            uid: '42ebac9fe00140b9b62ada863d44cee8',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                text: 'Automate the request and receipt of medical records, eliminating time-consuming data entry and enabling you to prepare more demands faster. ',
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
              },
            ],
          },
          {
            uid: '19615f27b08b4c1fadc6cb71c9236955',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
                text: '',
              },
            ],
          },
          {
            uid: 'bca6df804ae645c2be52067a8cb6905b',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
                text: 'Schedule your ',
                bold: true,
              },
              {
                uid: '256a96f9ffa9402e808a6ed4ad97f4dd',
                type: 'a',
                attrs: {
                  url: 'https://www.lexitaslegal.com/solutions/automated-records-collection?assembly',
                  target: '_blank',
                },
                children: [
                  {
                    attrs: {
                      style: {
                        'font-size': '11pt',
                        color: 'rgb(0, 0, 0)',
                        'font-family': 'Arial',
                      },
                    },
                    text: 'Lexitas demo',
                    bold: true,
                  },
                ],
              },
              {
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
                text: ' today',
                bold: true,
              },
            ],
          },
        ],
        _version: 4,
      },
      partner_logo: {
        image_alt_text: 'Lexitas logo',
        image: {
          dimension: {
            width: 183,
            height: 50,
          },
          filename: 'Lexitas.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=46&height=13&format=webp&quality=90 46w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=92&height=25&format=webp&quality=90 92w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=183&height=50&format=webp&quality=90 183w',
                  sizes: '(min-width: 183px) 183px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=183&height=50&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=46&height=13&format=png&quality=90 46w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=92&height=25&format=png&quality=90 92w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=183&height=50&format=png&quality=90 183w',
                sizes: '(min-width: 183px) 183px, 100vw',
              },
            },
            layout: 'constrained',
            width: 183,
            height: 50,
          },
        },
      },
    },
  ],
  components: [
    {
      Simple_Promo: {
        background_color: 'dark',
        centered: true,
        simple_promo: [
          {
            button: {
              external_link: false,
              link_text: 'Get a Demo',
              link_url: '/get-a-demo',
            },
            headline: 'Ready to see our system in action?',
            id: '48057656-541d-5cec-85bf-d2ea73800e94',
            uid: 'blta31abb1be7d7eafd',
            promo_text: {
              type: 'doc',
              attrs: {},
              uid: '429fce685d8a47fdb41f5075904c48ff',
              children: [
                {
                  type: 'p',
                  attrs: {},
                  uid: 'be8e9225c5ed46beba553a7f321c6954',
                  children: [
                    {
                      text: 'Let us show you how Neos can help you do more in less time. Schedule a demo today.',
                      attrs: {
                        style: {
                          'font-size': '11pt',
                          color: 'rgb(0, 0, 0)',
                          'font-family': 'Arial',
                        },
                      },
                    },
                  ],
                },
              ],
              _version: 2,
            },
            title: 'Ready to see our system in action?',
          },
        ],
      },
      Full_Width_Promo: null,
      boxed_promo: null,
      Promo_Cards: null,
    },
  ],
  hasGraphQL: false,
  partnerCardNodes: [
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '2b87951730794402bd2f607950a8a7a3',
        children: [
          {
            uid: '4812044ca20e4c8b8fc519b88626292c',
            type: 'p',
            attrs: {},
            children: [
              {
                text: 'Reduce phone calls and increase client satisfaction. Automate case updates, send messages, share files, collect form data, get 5-star reviews, and more — directly from Neos.',
              },
            ],
          },
          {
            uid: 'c120cb46578d4c7499741a006930c405',
            type: 'p',
            attrs: {},
            children: [
              {
                text: '',
                bold: true,
              },
            ],
          },
          {
            uid: 'c50c077e542d4cd98a8a7c654046174a',
            type: 'p',
            attrs: {},
            children: [
              {
                text: 'Schedule your ',
                bold: true,
              },
              {
                uid: 'd8b1e602a34849bea7ffe606df339a7c',
                type: 'a',
                attrs: {
                  url: 'https://casepulse.com/neos/?ref=assembly',
                  target: '_blank',
                },
                children: [
                  {
                    bold: true,
                    text: 'CasePulse demo',
                  },
                ],
              },
              {
                text: ' today',
                bold: true,
              },
            ],
          },
          {
            uid: '60ca4322377d4df3b22e57765c61fe50',
            type: 'p',
            attrs: {},
            children: [
              {
                text: '',
              },
            ],
          },
        ],
        _version: 12,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 200,
            height: 44,
          },
          filename: 'casepulse-logo.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=50&height=11&format=webp&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=100&height=22&format=webp&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=200&height=44&format=webp&quality=90 200w',
                  sizes: '(min-width: 200px) 200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=200&height=44&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=50&height=11&format=png&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=100&height=22&format=png&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdd9f20ab4b0b989e/62e1a617df0a3871e08a5287/casepulse-logo.png?width=200&height=44&format=png&quality=90 200w',
                sizes: '(min-width: 200px) 200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 200,
            height: 43.99999999999999,
          },
        },
        image_alt_text: 'CasePulse',
      },
      partner_name: 'Mock Partner Card 1',
      title: 'CasePulse Client Portal',
      id: '81695d6a-b92c-50db-82c3-cb5541200e6c',
      uid: 'blt6ab9bb7c812a0860',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: 'af33df9f6ce04c72b62b3e20d4d171cd',
        children: [
          {
            uid: '2fc321b3586346c5bfb1d87400f792ce',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                text: 'Track and recover costs, automate the document lifecycle and break down scanning workflow bottlenecks.',
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
              },
            ],
          },
        ],
        _version: 3,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 394,
            height: 150,
          },
          filename: 'Copitrak-logo_new.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=99&height=38&format=webp&quality=90 99w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=197&height=75&format=webp&quality=90 197w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=394&height=150&format=webp&quality=90 394w',
                  sizes: '(min-width: 394px) 394px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=394&height=150&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=99&height=38&format=png&quality=90 99w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=197&height=75&format=png&quality=90 197w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd521eaaa394dc517/62b5d652fb425b0fc910e600/Copitrak-logo_new.png?width=394&height=150&format=png&quality=90 394w',
                sizes: '(min-width: 394px) 394px, 100vw',
              },
            },
            layout: 'constrained',
            width: 394,
            height: 150,
          },
        },
        image_alt_text: 'Copitrak logo',
      },
      partner_name: 'Mock Partner Card 2',
      title: 'Copitrak Partner',
      id: '941427b7-91de-5c4c-9e38-1b6ce377944b',
      uid: 'blt10e2675a45dd3381',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '1a9c6c995e5c4f2c8c52e77ebb269d64',
        children: [
          {
            uid: '6aa354f0f8f0470e9f78384ab086b3b2',
            type: 'p',
            attrs: {},
            children: [
              {
                text: 'Electronic disbursements will save your firm both time and money on processing checks, automate record keeping, and improve client experience. Send funds to clients using PayPal, ACH, eDebit cards, and more.',
              },
            ],
          },
          {
            uid: 'dc350c362ac840698a3573eea7ce0a09',
            type: 'p',
            attrs: {},
            children: [
              {
                text: '',
              },
            ],
          },
          {
            uid: 'bd5d32411bb9458695c8c11b8fcc1aea',
            type: 'p',
            attrs: {},
            children: [
              {
                text: '',
              },
              {
                uid: '51cd92a9eb9d4a95acf5b6e7299257c8',
                type: 'span',
                attrs: {},
                children: [
                  {
                    text: 'Please get in touch with your account manager to learn more.',
                  },
                ],
              },
              {
                text: '',
              },
            ],
          },
        ],
        _version: 3,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 239,
            height: 49,
          },
          filename: 'logo2x.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=60&height=12&format=webp&quality=90 60w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=120&height=25&format=webp&quality=90 120w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=239&height=49&format=webp&quality=90 239w',
                  sizes: '(min-width: 239px) 239px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=239&height=49&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=60&height=12&format=png&quality=90 60w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=120&height=25&format=png&quality=90 120w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltfa58a497cf46096b/63b5bc11897e7411813ae96d/logo2x.png?width=239&height=49&format=png&quality=90 239w',
                sizes: '(min-width: 239px) 239px, 100vw',
              },
            },
            layout: 'constrained',
            width: 239,
            height: 49,
          },
        },
        image_alt_text: 'Digital Disbursements logo',
      },
      partner_name: 'Mock Partner Card 3',
      title: 'Digital Disbursements',
      id: 'fe10a0a0-b4b2-5b08-9e6d-22596703c7f8',
      uid: 'bltf7867fe0e30c477c',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: 'c6d5a68c6c334e74b7d9cda975eebfce',
        children: [
          {
            uid: '92a9ede81b2f40778eb49a0f55e1682d',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                text: 'Send, sign, and approve paperless documents, improving collaboration among staff members and clients.',
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
              },
            ],
          },
        ],
        _version: 2,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 236,
            height: 83,
          },
          filename: 'docusign_logo_black_text_on_white_0_1.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=59&height=21&format=webp&quality=90 59w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=118&height=42&format=webp&quality=90 118w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=236&height=83&format=webp&quality=90 236w',
                  sizes: '(min-width: 236px) 236px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=236&height=83&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=59&height=21&format=png&quality=90 59w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=118&height=42&format=png&quality=90 118w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt47e7d8127b4f8010/62a13cba1da22658512947a8/docusign_logo_black_text_on_white_0_1.png?width=236&height=83&format=png&quality=90 236w',
                sizes: '(min-width: 236px) 236px, 100vw',
              },
            },
            layout: 'constrained',
            width: 236,
            height: 83,
          },
        },
        image_alt_text: 'DocuSign logo',
      },
      partner_name: 'Mock Partner Card 4',
      title: 'DocuSign Partner',
      id: '80d64593-0816-5ae0-94c0-a32de43ede63',
      uid: 'blta5e889721b0acc1a',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '84e19fe1dd1447f59b61d766f84f05c5',
        children: [
          {
            type: 'p',
            attrs: {},
            uid: 'e853585cba804b0882674e3b14f9834d',
            children: [
              {
                text: 'Interface with your existing cost-recovery solution to track and recover all costs associated with the document lifecycle.',
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
              },
            ],
          },
        ],
        _version: 4,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 622,
            height: 230,
          },
          filename: 'Equitrac_logo_white.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=156&height=58&format=webp&quality=90 156w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=311&height=115&format=webp&quality=90 311w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=622&height=230&format=webp&quality=90 622w',
                  sizes: '(min-width: 622px) 622px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=622&height=230&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=156&height=58&format=png&quality=90 156w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=311&height=115&format=png&quality=90 311w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltea71406038b41b8b/62b4a8864086f70f9e5b2fd8/Equitrac_logo_white.png?width=622&height=230&format=png&quality=90 622w',
                sizes: '(min-width: 622px) 622px, 100vw',
              },
            },
            layout: 'constrained',
            width: 622,
            height: 230,
          },
        },
        image_alt_text: 'Equitrac logo',
      },
      partner_name: 'Mock Partner Card 5',
      title: 'Equitrac Partner',
      id: '0e88011f-9ab7-56e5-ae34-d644f9ad0ec4',
      uid: 'blt92131921e8ade928',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '5a4d450e05cb40a3a237032643216603',
        children: [
          {
            type: 'p',
            attrs: {},
            uid: '90f94326182d4368b9e828f70704c38f',
            children: [
              {
                text: 'Reduce duplication of efforts for billing and invoicing, leveraging the comprehensive functionality of QuickBooks®, both online and desktop versions.',
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
              },
            ],
          },
        ],
        _version: 3,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 237,
            height: 64,
          },
          filename: 'Intuit_QuickBooks_logo_1.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=59&height=16&format=webp&quality=90 59w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=119&height=32&format=webp&quality=90 119w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=237&height=64&format=webp&quality=90 237w',
                  sizes: '(min-width: 237px) 237px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=237&height=64&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=59&height=16&format=png&quality=90 59w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=119&height=32&format=png&quality=90 119w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3debe9dd6f110ab4/62a13cba4a98d755621a1f0e/Intuit_QuickBooks_logo_1.png?width=237&height=64&format=png&quality=90 237w',
                sizes: '(min-width: 237px) 237px, 100vw',
              },
            },
            layout: 'constrained',
            width: 237,
            height: 64,
          },
        },
        image_alt_text: 'Intuit QuickBooks logo',
      },
      partner_name: 'Mock Partner Card 6',
      title: 'Intuit QuickBooks Partner',
      id: 'eb2d2c13-e61e-50ec-a392-c4407415845e',
      uid: 'blt5fdc666a57540f8c',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: 'c1850fe0f00c467bb3a67a2cd6d77248',
        children: [
          {
            type: 'p',
            attrs: {},
            uid: '7ca13c1530bd46db95f3a55793a2b42a',
            children: [
              {
                text: 'SMS marketing at its finest! Send mass texts, generate intakes via text, and send automated messages to clients. The texts flow into your Kenect text inbox where you can respond to them, intelligently route them to individuals, teams, locations, or departments, and even create auto-responses.',
              },
            ],
          },
          {
            type: 'p',
            attrs: {},
            uid: '5d69adbd4ebd4bc1acafd708dc65ddb3',
            children: [
              {
                text: '',
                bold: true,
              },
            ],
          },
          {
            type: 'p',
            attrs: {},
            uid: '137bcaf25630488fb31f700e5bf3e90a',
            children: [
              {
                bold: true,
                text: 'Sign up for ',
              },
              {
                uid: 'efe1f8fc46534183a92d6d64ab1742b0',
                type: 'a',
                attrs: {
                  url: 'https://smart.kenect.com/assembly-neos',
                  target: '_blank',
                },
                children: [
                  {
                    text: 'Kenect demo',
                    bold: true,
                  },
                ],
              },
              {
                text: ' today',
                bold: true,
              },
            ],
          },
        ],
        _version: 6,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 200,
            height: 35,
          },
          filename: 'Kenect_Logo.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=50&height=9&format=webp&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=100&height=18&format=webp&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=200&height=35&format=webp&quality=90 200w',
                  sizes: '(min-width: 200px) 200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=200&height=35&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=50&height=9&format=png&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=100&height=18&format=png&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt4cf5b1872fb76c54/64133ac32f735255e41354dc/Kenect_Logo.png?width=200&height=35&format=png&quality=90 200w',
                sizes: '(min-width: 200px) 200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 200,
            height: 35,
          },
        },
        image_alt_text: 'Kenect logo',
      },
      partner_name: 'Mock Partner Card 7',
      title: 'Kenect',
      id: '340d8ce5-a88f-51da-8a49-f20a90b11e88',
      uid: 'bltd0843185ca373754',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '9a98bfad6496447baa312d2bfb9af173',
        children: [
          {
            type: 'p',
            uid: 'a0ae761802c54ab696c53a6370cf08e6',
            attrs: {},
            children: [
              {
                text: 'Get paid faster with zero hassle. Accept payments online, send links to a secure payment portal via email, and more.',
              },
            ],
          },
          {
            type: 'p',
            uid: '78fed8ff19c64d078acbbab2482cf561',
            attrs: {},
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'p',
            uid: 'c4e476dbe59f4e2db013e1c1ee9caefa',
            attrs: {},
            children: [
              {
                text: 'Schedule your ',
                bold: true,
              },
              {
                uid: '4c0252e4433c48ea92d13160c740255c',
                type: 'a',
                attrs: {
                  url: 'https://www.lawpay.com/partners/assembly-software/',
                  target: '_blank',
                },
                children: [
                  {
                    bold: true,
                    text: 'LawPay demo',
                  },
                ],
              },
              {
                bold: true,
                text: ' today',
              },
            ],
          },
        ],
        _version: 13,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 200,
            height: 67,
          },
          filename: 'LawPay_Logo.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=50&height=17&format=webp&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=100&height=34&format=webp&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=200&height=67&format=webp&quality=90 200w',
                  sizes: '(min-width: 200px) 200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=200&height=67&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=50&height=17&format=png&quality=90 50w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=100&height=34&format=png&quality=90 100w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltae64c74656a0cc0c/62d869eff900c40ffde1c013/LawPay_Logo.png?width=200&height=67&format=png&quality=90 200w',
                sizes: '(min-width: 200px) 200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 200,
            height: 67,
          },
        },
        image_alt_text: 'LawPay Logo',
      },
      partner_name: 'Mock Partner Card 8',
      title: 'LawPay Partner',
      id: '1d37dc2b-a7aa-5e82-b13c-254c7d3576f7',
      uid: 'blt524ba3cd104f7848',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: 'ae39092707604774b5950fe2ecc7dbea',
        children: [
          {
            uid: '42ebac9fe00140b9b62ada863d44cee8',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                text: 'Automate the request and receipt of medical records, eliminating time-consuming data entry and enabling you to prepare more demands faster. ',
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
              },
            ],
          },
          {
            uid: '19615f27b08b4c1fadc6cb71c9236955',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
                text: '',
              },
            ],
          },
          {
            uid: 'bca6df804ae645c2be52067a8cb6905b',
            type: 'p',
            attrs: {
              style: {
                'text-align': 'left',
              },
            },
            children: [
              {
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
                text: 'Schedule your ',
                bold: true,
              },
              {
                uid: '256a96f9ffa9402e808a6ed4ad97f4dd',
                type: 'a',
                attrs: {
                  url: 'https://www.lexitaslegal.com/solutions/automated-records-collection?assembly',
                  target: '_blank',
                },
                children: [
                  {
                    attrs: {
                      style: {
                        'font-size': '11pt',
                        color: 'rgb(0, 0, 0)',
                        'font-family': 'Arial',
                      },
                    },
                    text: 'Lexitas demo',
                    bold: true,
                  },
                ],
              },
              {
                attrs: {
                  style: {
                    'font-size': '11pt',
                    color: 'rgb(0, 0, 0)',
                    'font-family': 'Arial',
                  },
                },
                text: ' today',
                bold: true,
              },
            ],
          },
        ],
        _version: 4,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 183,
            height: 50,
          },
          filename: 'Lexitas.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=46&height=13&format=webp&quality=90 46w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=92&height=25&format=webp&quality=90 92w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=183&height=50&format=webp&quality=90 183w',
                  sizes: '(min-width: 183px) 183px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=183&height=50&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=46&height=13&format=png&quality=90 46w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=92&height=25&format=png&quality=90 92w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt053cbafbda3a3651/62a13cbace3d8856b19f8a4c/Lexitas.png?width=183&height=50&format=png&quality=90 183w',
                sizes: '(min-width: 183px) 183px, 100vw',
              },
            },
            layout: 'constrained',
            width: 183,
            height: 50,
          },
        },
        image_alt_text: 'Lexitas logo',
      },
      partner_name: 'Mock Partner Card 9',
      title: 'Lexitas Partner',
      id: '18325bcc-7c97-5778-8391-3089f590235e',
      uid: 'blte93013f8df100a42',
    },
    {
      card_content: {
        type: 'doc',
        attrs: {},
        uid: '0f675676c86740ba8803d333e9cc6fa3',
        children: [
          {
            uid: '282b8c3655a34af48f201065f0d1dda3',
            type: 'p',
            attrs: {},
            children: [
              {
                text: 'Use Mailchimp to send automatic bulk communications to keep clients engaged, promote new services, share updates, and more.',
              },
            ],
          },
        ],
        _version: 5,
      },
      partner_logo: {
        image: {
          dimension: {
            width: 267,
            height: 150,
          },
          filename: 'Mailchimp-Logo.png',
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png',
          is_dir: false,
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=67&height=38&format=webp&quality=90 67w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=134&height=75&format=webp&quality=90 134w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=267&height=150&format=webp&quality=90 267w',
                  sizes: '(min-width: 267px) 267px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=267&height=150&format=png&quality=90',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=67&height=38&format=png&quality=90 67w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=134&height=75&format=png&quality=90 134w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt381a2d2181e21c7c/635aaedbec6d3178dbbbf1e2/Mailchimp-Logo.png?width=267&height=150&format=png&quality=90 267w',
                sizes: '(min-width: 267px) 267px, 100vw',
              },
            },
            layout: 'constrained',
            width: 267,
            height: 150,
          },
        },
        image_alt_text: 'Neos Mailchimp integration',
      },
      partner_name: 'Mock Partner Card 10',
      title: 'Mailchimp',
      id: '7660520c-4d51-57a0-ba26-7cbaba70ba58',
      uid: 'blt093ad36cb3218438',
    },
  ],
  header_style: {
    header_style: 'dark',
  },
};
