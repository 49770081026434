import React, { useEffect, useState, useCallback } from 'react';
import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { LCLanding } from '@cms-components/learning-center/lc-landing/lc-landing';
import { lcLandingMapper } from '@cms-components/learning-center/lc-landing/mappers/lc-landing.mapper';
import {
  FEATURED_CARDS_CATEGORIES_MOCK,
  FEATURED_CARDS_MOCK,
} from '@shared/contentstack/live-preview/mock-data/featured-cards-mock';
import IntegrationPartnerPage from '@templates/integration-partner/integration-partner';
import { INTEGRATED_PARTNERS_MOCK } from '@shared/contentstack/live-preview/mock-data/integrated-partners-mock';

const IntegrationPartnerPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'partner_page',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData ? (
    <IntegrationPartnerPage
      pageContext={{ ...livePreviewData, ...INTEGRATED_PARTNERS_MOCK }}
    />
  ) : (
    <></>
  );
};

export default IntegrationPartnerPreviewPage;
