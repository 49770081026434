import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { color_darkest_grey } from '@src/styles/colors';

export const ContainerItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getStyleSizes(25)};
  margin-bottom: ${getStyleSizes(50)};

  ${mq_min('lg')} {
    grid-gap: ${getStyleSizes(29)};
    grid-template-columns: 1fr 1fr 1fr;
  }

  & p {
    font-size: ${getStyleSizes(13)};
    font-family: NoToSans;
  }
`;

export const PaginationContainer = styled.div`
  width: 100%;
  text-align: center;
  & p {
    font-size: ${getStyleSizes(13)};
    font-family: NoToSans;
  }

  & button {
    margin-bottom: ${getStyleSizes(16)};
  }
`;

export const SectionHeading = styled.h2`
  font-weight: 400;
  font-size: ${getStyleSizes(26)};
  margin-bottom: ${getStyleSizes(20)};
  color: ${color_darkest_grey};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(28)};
  }
`;
